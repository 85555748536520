<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <div class="d-flex flex-wrap">

      </div>
    </b-media>

    <!-- Client Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Client Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="clientData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Description -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="clientData.description"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Status"
            label-for="client-status"
          >
            <v-select
              v-model="clientData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="client-status"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateClient(clientData._id)"
    >
      Save Changes
    </b-button>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    
    const statusOptions = [
      { label: 'True', value: true },
      { label: 'False', value: false },
    ]

    return {
     statusOptions,
    }
  },
  methods: {
    updateClient(Id) {
      console.log(Id)
      store.dispatch('app-client/updateClient',  this.clientData )
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Client updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
